import * as React from "react";
import { observer } from "mobx-react";
import { withStores } from "stores";
import { UitkSpacing } from "@egds/react-core/spacing";
import { SpacingContainerProps, SpacingContainerFlexModuleResult } from "./typings";
import { hasRenderableChildren } from "src/stores/ExperienceTemplateStore/shouldRender";
import { RegionChildren } from "src/utils/RegionUtils";

const DEFAULT_SPACING = "six";
const DEFAULT_INLINE_SPACING = "three";

export const SpacingContainer = withStores(
  "flexModuleModelStore",
  "context"
)(
  observer((props: SpacingContainerProps) => {
    const { flexModuleModelStore, templateComponent, blossomComponent } = props;

    /* istanbul ignore if */
    if (!templateComponent || !blossomComponent || !flexModuleModelStore) {
      return null;
    }

    const {
      metadata: { id },
    } = templateComponent;
    const model = (flexModuleModelStore.getModel(id) as SpacingContainerFlexModuleResult) || null;

    if (!model || !hasRenderableChildren(templateComponent, flexModuleModelStore)) {
      return null;
    }

    const { a11yRegionLabel, a11yRegionAriaLabelTitle, spacingContainer, hasNoInlineSpacing } = model;

    const regionSpecificLabel = a11yRegionAriaLabelTitle || a11yRegionLabel || "";
    const spacingValue = spacingContainer || DEFAULT_SPACING;
    const inlineSpacingValue = hasNoInlineSpacing ? "unset" : DEFAULT_INLINE_SPACING;

    const regionA11yAttrs: any = {};
    if (regionSpecificLabel) {
      regionA11yAttrs["role"] = "region";
      regionA11yAttrs["aria-label"] = regionSpecificLabel;
    }

    return (
      <UitkSpacing padding={{ block: spacingValue, inline: inlineSpacingValue }}>
        <div
          className={`SpacingContainer ${hasNoInlineSpacing ? " inlineSpacingContainerUnset" : ""}`}
          {...regionA11yAttrs}
        >
          {templateComponent.children && (
            <RegionChildren templateComponent={templateComponent} blossomComponent={blossomComponent} />
          )}
        </div>
      </UitkSpacing>
    );
  })
);

export default SpacingContainer;
